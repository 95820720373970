import * as React from 'react';

import {
    IActivityType,
    IContractType,
    IDepartment,
    IJob,
    IJobHasDepartment,
    IJobStatus,
    IPropertyGroupType,
    IResourceStatusType, ISecurity,
    IUserIdentity,
    IWork,
    IWorker,
    IWorkShift,
    IWorkspace,
    jobStatusTypes,
    ModuleManager,
} from '@models';
import {
    useLoading,
    useTranslation,
    Portal,
    useFormDialog,
    useSearchPaginate,
    usePaginator,
    useSearchFilters,
    useSearch
} from '@components';
import { JobForm } from '@components/admin/JobForm';
import { JobCard } from '@components/jobs/list/JobCard';
import { goToJob } from '@components/routes';
import { IMachineryType, IWorkerDocumentType, IWorkerType } from '@models/resources';

import './Jobs.scss';

type GetJobStatusT = (workId: number, jobId: number) => Promise<IJobStatus[]>;
type IJobRow = IJob & {
    currentJobHasContractorId?: number;
}

export interface IProps {
    user: any;
    activityTypes: IActivityType[];
    clearSelectedJob: Function;
    contractTypes: IContractType[];
    departments: IDepartment[];
    propertyGroupTypes: IPropertyGroupType[];
    getJobs: Function;
    getJobContractorsViewModel: Function;
    saveJob: Function;
    security: ISecurity;
    moduleManager: ModuleManager;
    jobs: IJob[];
    work: IWork;
    workers: IWorker[];
    workShifts: IWorkShift[];
    workspaces: IWorkspace[];
    resourceStatusTypes: IResourceStatusType[];
    workerDocumentTypes: IWorkerDocumentType[];
    workerTypes: IWorkerType[];
    machineryTypes: IMachineryType[];
    getJobStatus: GetJobStatusT;
}

function SelectJob({
    activityTypes, security, workspaces, jobs,
    workerDocumentTypes, machineryTypes, workerTypes, work, initialize, moduleManager
}: {
    activityTypes: IActivityType[],
    departments: IDepartment[],
    getJobContractorsViewModel: Function,
    security: ISecurity,
    workers: IWorker[],
    workspaces: IWorkspace[],
    workerDocumentTypes: IWorkerDocumentType[],
    workerTypes: IWorkerType[],
    machineryTypes: IMachineryType[],
    jobs: IJobRow[],
    selectJob: Function,
    work: IWork,
    initialize: Function,
    getJobStatus: Function;
    user?: IUserIdentity;
    moduleManager: ModuleManager;
}) {

    return <div className='SelectJob r h-center'>
        {(jobs ?? []).map((j, i) =>
            <JobCard
                key={j.id}
                job={j}
                security={security}
                workspaces={workspaces}
                activityTypes={activityTypes}
                workerDocumentTypes={workerDocumentTypes}
                workerTypes={workerTypes}
                machineryTypes={machineryTypes}
                work={work}
                reload={initialize}
                currentJobHasContractorId={j.currentJobHasContractorId}
                moduleManager={moduleManager} />
        )}
    </div>
}

enum JobListMode {
    MYJOBS = 0,
    DEPARTMENT = 1,
    ALLJOBS = 2,
    INVOLVED = 3,
    SUBCONTRACTED = 4,
}

let __jobListMode = JobListMode.ALLJOBS;

export function JobsImpl(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading(false);
    const [jobListMode, setJobListMode] = React.useState<JobListMode>(__jobListMode);
    const [canCreateJobState, setCanCreateJobState] = React.useState<boolean>(false);

    const isOblique = React.useRef(false);

    const isNotOblique = React.useRef(false);

    const workerIds = props.security.getWorkerIds();

    const departments = props.departments;

    React.useEffect(() => {
        props.clearSelectedJob();
    }, []);

    const [responsibles, setResponsibles] = React.useState<{ id: number, name: string }[]>([]);
    const [dataValue, setDataValue] = React.useState<IJob[]>([]);

    const [contractorParents, setContractorParents] = React.useState<{ id: number, name: string }[]>([]);

    React.useEffect(() => {
        __jobListMode = jobListMode;
    }, [jobListMode]);

    const formDialog = useFormDialog({
        editTitle: t('Edit job'),
        addTitle: t('Add job'),
    });

    const calculateIsOblique = () => {
        for (let i = 0; i < workerIds.length; i++) {
            const w = workerIds[i];

            for (let j = 0; j < departments.length; j++) {
                const d = departments[j];

                if (d.workers?.some(wd => w == wd.id) && d.isOblique) {
                    isOblique.current = true;
                }

                if (d.workers?.some(wd => w == wd.id) && !d.isOblique) {

                    isNotOblique.current = true;
                }
            }
        }
    };

    const getSearch = () => {
        calculateIsOblique();
        if (props.security.isContractor() && ![23,22].includes(props.work.id)) {
            return 'jobList/contractors';
        }
        else if (props.security.isGestor()) {
            return 'jobList/gestor';
        }
        else if (jobListMode === JobListMode.ALLJOBS && props.security.hasPolicy('jobs.list') && ![23,22].includes(props.work.id)) {
            return 'jobList/gestor';
        }
        else if (jobListMode === JobListMode.ALLJOBS && !props.security.hasPolicy('jobs.list') && ![23,22].includes(props.work.id)) {
            return 'jobList/workers.all'
        }
        else if ([23,22].includes(props.work.id) && jobListMode === JobListMode.ALLJOBS && isOblique.current && props.security.isWorker()) {
            return 'jobList/gestor'
        }
        else if ([23,22].includes(props.work.id) && jobListMode === JobListMode.ALLJOBS && isNotOblique.current && props.security.isWorker()) {
            return 'jobList/workers.oblique.all'
        }
        else if ([23,22].includes(props.work.id) && jobListMode === JobListMode.ALLJOBS && props.security.isContractor()) {
            return 'jobList/contractors.all'
        }
        else if (jobListMode === JobListMode.MYJOBS && ![23,22].includes(props.work.id)) {
            return 'jobList/workers.own';
        }
        else if (jobListMode === JobListMode.MYJOBS && [23,22].includes(props.work.id) && props.security.isWorker()) {

            return 'jobList/workers.own.azsa';
        }
        else if (jobListMode === JobListMode.MYJOBS && [23,22].includes(props.work.id) && props.security.isContractor()) {
            return 'jobList/contractors.own';
        }
        else if (jobListMode === JobListMode.DEPARTMENT && ![23,22].includes(props.work.id)) {
            return 'jobList/departments.all';
        }
        else if (jobListMode === JobListMode.DEPARTMENT && [23,22].includes(props.work.id)) {
            return 'jobList/departments.azsa.all';
        }
        else if ([23,22].includes(props.work.id) && jobListMode === JobListMode.INVOLVED) {
            return 'jobList/workers.involved'
        }
        else if ([23,22].includes(props.work.id) && jobListMode === JobListMode.SUBCONTRACTED) {
            return 'jobList/contractors.subcontracted'
        }
        else {
            return '';
        }
    }

    const mergeFilters = (filters: any) => {
        if (filters.current_status_type && filters.current_status_type != 0) {
            return filters;
        }
        else {
            if (filters.current_status_type == 0) {
                userFilters.setFilter('current_status_type', null);
            }
            return { ...filters, exclude_status: 4 };
        }
    }

    const parentsSearch = useSearch<any>({
        workId: props.work.id,
        search: 'jobList/parents',
    });

    const worksSpacesSearch = useSearch<any>({
        workId: props.work.id,
        search: 'jobList/workspaces',
        filters: {
            workerId: props.work.id,
        },
    });

    const responsiblesSearch = useSearch<any>({
        workId: props.work.id,
        search: 'jobList/oblique.responsibles',
        filters: {
            workId: props.work.id,
        },
    });

    const [contractors, setContractors] = React.useState<any[]>([]);
    const contractorsSearch = useSearch<any>({
        workId: props.work.id,
        search: 'jobList/contractorsOptions',
        filters: {
            workId: props.work.id,
        },
    });

    React.useEffect(() => {
        const options = contractorsSearch.value.map((c: any) => ({ id: c.id, name: c.name.trim() }));
        setContractors(options.sort((a, b) => a.name.localeCompare(b.name)));
    }, [contractorsSearch.value]);

    const calculateWorkSpaces = () => {
        if (props.security.isWorker()) {
            const result: any = [];
            worksSpacesSearch.value?.forEach((w: any) => {
                result.push({ id: w.id, name: w.name })
            })
            return result;
        } else {
            return props.workspaces
        }
    };

    const filterStatusTypes = props.moduleManager.filterDependency('job.statusTypes', {
        security: props.security,
        work: props.work,
    });

    const userFilters = useSearchFilters({
        security: props.security,
        workId: props.work.id,
        name: getSearch(),
        references: {
            workspace: calculateWorkSpaces(),
            current_status_type: jobStatusTypes.filter(filterStatusTypes),
            responsible: responsibles,
            parent_contractor: Array.from(new Set(contractorParents)),
            select_contractor: contractors,
        },
        persist: true
    });

    React.useEffect(() => {
        props.security.isContractor() && parentsSearch.doSearch() && responsiblesSearch.doSearch();
        props.security.isWorker() && worksSpacesSearch.doSearch();
        contractorsSearch.doSearch();
    }, []);

    React.useEffect(() => {
        setResponsibles(responsiblesSearch.value)
    }, [props, responsiblesSearch.value]);

    const data = useSearchPaginate<IJobRow>({
        workId: props.work.id,
        normalizeKeys: true,
        filters: {
            workId: props.work.id,
            department_id: props.security.user?.departments,
            responsibleId: props.security.getWorkerIds()[0],
            ...mergeFilters(userFilters.filters),
        },
        search: getSearch(),
        dependencies: [userFilters.filters, jobListMode],
        limit: 8,
    });

    React.useEffect(() => {
        setDataValue(data.data);
    }, [data.data]);

    React.useEffect(() => {
        if (data.data && data.data.length > 0) {
            calculateParentContractors();
        }
        if (userFilters.filters.parent_contractor) {
            filterByParentContractor(data.data);
        }
    }, [data.data]);

    const calculateParentContractors = () => {
        const parents = parentsSearch.value;
        (parents && parents[0]?.id != 0) && parents.unshift({ id: 0, name: "AZSA" });
        setContractorParents(parentsSearch.value);
    };

    const filterByParentContractor = (jobs: any) => {

        const result = jobs.filter((j: any) => j.parentContractorId == (userFilters.filters.parent_contractor == 0 ? null : userFilters.filters.parent_contractor));
        setDataValue(result);
    }

    const paginator = usePaginator<IJob>({
        className: 'transparent lg pd-right',
        data: data.value,
        rowNumbers: [8, 16, 32, 64],
        onChange: (page, limit) => data.doSearch({
            workId: props.work.id, ...mergeFilters(userFilters.filters), department_id: props.security.user?.departments,
            responsibleId: props.security.getWorkerIds()[0]
        }, page, limit),
    });

    const reload = () => {
        data.doSearch();
    }

    const saveJob = loading.wrap(async (w: IJob, departments: IJobHasDepartment[]) => {
        await props.saveJob(w, departments);
        await data.doSearch();

        formDialog.clear();
    });

    const calculateOptions = () => {
        const options = [];
        if (props.work.id == 23 && props.security.isWorker()) {
            options.push(<>
                <option className={'font'} value={JobListMode.ALLJOBS}>{t('jobs.list.allJobs.pessma')}</option>
                <option className={'font'} value={JobListMode.MYJOBS}>{t('jobs.list.myjobs.pessma')}</option>
                <option className={'font'} value={JobListMode.INVOLVED}>{t('jobs.list.involved')}</option>
                <option className={'font'} value={JobListMode.DEPARTMENT}>{t('jobs.list.departments.pessma')}</option>
            </>)
        } else if (props.work.id == 23 && props.security.isContractor()) {
            options.push(<>
                <option className={'font'} value={JobListMode.ALLJOBS}>{t('jobs.list.allJobs.pessma')}</option>
                <option className={'font'} value={JobListMode.MYJOBS}>{t('jobs.list.myjobs.pessma')}</option>
                <option className={'font'} value={JobListMode.SUBCONTRACTED}>{t('jobs.list.subcontracted')}</option>
            </>)
        }
        else {
            options.push(<><option className={'font'} value={JobListMode.ALLJOBS}>{t('jobs.list.allJobs')}</option>
                <option className={'font'} value={JobListMode.MYJOBS}>{t('jobs.list.myjobs')}</option>
                <option className={'font'} value={JobListMode.DEPARTMENT}>{t('jobs.list.departments')}</option></>)
        }
        return <select className='transparent' value={jobListMode} onChange={e => setJobListMode(parseInt(e.target.value))}>
            {options}
        </select>
    }

    const calculateCreateJob = async () => {
        const ctx = {
            security: props.security,
            work: props.work,
        }
        const res = await props.moduleManager.requirePermission('jobs.create', ctx, props.security.hasPermission('jobs.create'));
        setCanCreateJobState(res ?? false);
    };

    React.useEffect(() => {
        calculateCreateJob();
    }, [props.moduleManager.getId()]);

    const selectJob = (jobId: number) => goToJob(props.work.id, jobId);
    return <div className='c'>
        {paginator(<userFilters.Component />)}

        <Portal container='#breadcrumb-right'>
            <div className='r'>
                {(props.security.isWorker() || (props.security.isContractor() && props.work.id === 23)) &&
                    calculateOptions()}
                {canCreateJobState &&
                    <button
                        onClick={() => formDialog.showAdd()}>
                        <i className='pi pi-plus sm pd-right' />
                        {t((props.work.id == 23 && props.security.isWorker()) ? 'Create job pessma' : 'Create job')}
                    </button>}
            </div>
        </Portal>

        {formDialog.render((data: IJob) =>
            <JobForm
                moduleManager={props.moduleManager}
                activityTypes={props.activityTypes}
                loading={loading}
                contractTypes={props.contractTypes}
                data={data}
                work={props.work}
                workId={props.work.id}
                notifications={[]}
                onSubmit={saveJob}
                workShifts={props.workShifts}
                workspaces={props.workspaces}
                departments={props.departments}
                security={props.security}
                propertyGroupTypes={props.propertyGroupTypes}
                footer={valid => <formDialog.Footer disabled={!valid || loading.isLoading()} />}
            />, [23,22].includes(props.work.id))}
        {data.renderLoading({
            text: t('jobs.list.loading')
        })}
        {<SelectJob
            activityTypes={props.activityTypes}
            departments={props.departments}
            jobs={dataValue}
            work={props.work}
            getJobContractorsViewModel={props.getJobContractorsViewModel}
            security={props.security}
            workers={props.workers}
            workspaces={props.workspaces}
            selectJob={selectJob}
            workerDocumentTypes={props.workerDocumentTypes}
            workerTypes={props.workerTypes}
            machineryTypes={props.machineryTypes}
            initialize={reload}
            getJobStatus={props.getJobStatus}
            moduleManager={props.moduleManager} />}
    </div>
}

export const Jobs = React.memo(JobsImpl, (prev, next) => {
    return prev.work.id === next.work.id
        && prev.moduleManager?.getId() == next.moduleManager?.getId();
});
