import * as React from 'react';

import {
    BreadcrumbItem, useConfirm, Portal, useLoading, useTreeTable, useTranslation,
    useRemoteData, useSearch, Tag, useSignalR, useScheduler, useDialogs, useOverlay,
    useSearchFilters,
    usePermissions,
} from '@components';
import EditJobHasContractorContainer from '@containers/jobs/EditJobHasContractorContainer';
import EditJobHasContractorFields from '@containers/jobs/EditJobHasContractorFieldsContainer';
import {
    IContractor, IJob, IJobHasContractor, IResourceStatusType, ISecurity, IUserIdentity,
    IWork, ResourceType, ModuleManager, IWorkSettings,
} from '@models';
import { redirectTo } from '@utils';
import { ResourceStatus } from '@components/common/ResourceStatus';
import { ResourceStatusType, resourceStatusTypes } from '@models/resources';
import { goToJobMachineries, goToJobSubContractors, goToJobWorkers } from '@components/routes';
import { IRequirementNotification } from '@models/requirements';
import ForceValidationResourceContainer from '@containers/requirements/ForceValidationResourceContainer';
import DateUtils from '@utils/date-utils';
import './JobContractors.scss';
import { useWorkFormSettings } from '@components/custom/useWorkFormSettings';

export interface IProps {
    activateJobHasContractor: Function;
    appUsers: IUserIdentity[];
    deleteJobHasContractor: Function;
    getContractorFromAppUser: Function;
    getJobContractors: Function;
    getJobHasContractor: Function;
    getJobContractorsViewModel: Function;
    hasPermission: Function;
    job: IJob;
    jobHasContractor?: IJobHasContractor;
    loadInvitations?: boolean;
    onRequestAddContractor: Function;
    parentId?: number;
    refreshContractors: Function;
    removeJobHasContractor: Function;
    resourceStatusTypes: IResourceStatusType[];
    security: ISecurity;
    selectJobHasContractor: Function;
    selectedJobHasContractor?: IJobHasContractor;
    getJobHasContractorFromId: Function;
    sourceId?: number;
    work: IWork;
    getContractor: Function;
    getJob: Function;
    selectedContractor?: IContractor;
    moduleManager: ModuleManager;
    clearSelectedContractor: Function;
    removeJobHasContractorRequest: Function;
    removeJobHasContractorInvitation: Function;
    contractorIds: number[];
}

enum ContractorType {
    CONTRACTOR = 1,
    INVITATION = 2,
    CONTRACTOR_REQUEST = 3,
}

type RowType = {
    id: number;
    jobId: number;
    contractorId: number;
    countContractorMachineries: number;
    countContractorWorkers: number;
    countContractorSubcontractors: number;
    countWorkersAuthorized: number;
    contractorName: string;
    contractorStartDate: string;
    contractorStatusType: number;
    jobHasContractorId: number;
    totalCount: number;
    validatedCount: number;
    workId: number;
    name: string;
    dateTime: string;
    currentStatusType: number;
    shipmentNumber?: string;
    parentId?: number;
    jobsDescription?: string;
    type: ContractorType;
    key?: string;
    hierarchyLevel: number;
    parentContractorId?: number;
    startDate?: string;
};

function isJobContractor(r: RowType) {
    return r.type == ContractorType.CONTRACTOR;
}

function isInvitation(r: RowType) {
    return r.type == ContractorType.INVITATION;
}

function isContractorRequest(r: RowType) {
    return r.type == ContractorType.CONTRACTOR_REQUEST;
}

export function JobContractorsImpl(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const dialogs = useDialogs();

    const [canRemoveState, setCanRemoveState] = React.useState<boolean>(false);

    const editOverlay = useOverlay({
        className: 'overlay-min',
        requireData: true,
        render: (d: any) =>
            <EditJobHasContractorFields
                workId={props.work.id}
                job={props.job}
                jobHasContractor={d.data}
                onClose={() => editOverlay.clear()}
                isDate={d.type === 'date'}
                onSuccess={initialize}>
                {(form: any) => <div>
                    {d.type === 'date' && form.input('startDate', { autoFocus: true, type: 'date' })}
                    {d.type === 'shipment-number' && form.input('shipmentNumber', { autoFocus: true })}
                    {d.type === 'description' && form.textarea('jobsDescription')}
                </div>}
            </EditJobHasContractorFields>
    });
    const initialize = () => {
        reload();
        editOverlay.clear();
    }
    const completeStatusList = [...resourceStatusTypes, { id: 6, name: t('job.status.pending') }]

    const filters = useSearchFilters({
        security: props.security,
        workId: props.work.id,
        name: 'jobs/contractors',
        references: {
            resourceStatusTypes: completeStatusList,
        },
        persist: true,
    });

    const currentJhc = props.security.isContractor()
        ? props.job.contractors.find(c => props.security.hasContractor(c.contractorId))
        : null;

    const parentId = props.parentId
        ?? props.selectedJobHasContractor?.id
        ?? currentJhc?.id;

    const query = useSearch<RowType>({
        workId: props.work.id,
        search: 'jobs/contractors',
        normalizeKeys: true,
        map: (r: RowType, i: number) => ({ ...r, key: i + '_' + r.id + '_' + r.parentId }),
        filters: filters.merge({
            jobId: props.job.id,
            workId: props.work.id,
            parentId: props.parentId,
            jobHasContractorId: parentId,
        }),
    });
    const selfJobHasContractorsIds = React.useRef<number[]>([]);
    const selfContractorId = React.useRef<number>(0);

    const [selectedContractor, setSelectedContractor] = React.useState<IJobHasContractor | undefined>(
        props.jobHasContractor);

    const scheduler = useScheduler();

    const _ = useSignalR<IRequirementNotification>({
        method: 'RequirementNotification',
        onMessage: r => {
            if (r.workId == props.work.id) {
                scheduler.schedule(reload, 2500);
            }
        }
    });

    const reload = () => {
        query.doSearch(
            filters.merge({
                jobId: props.job.id,
                workId: props.work.id
            })
        );
    }

    const doRemoveContractor = loading.wrap(async (i: IJobHasContractor) => {
        await props.removeJobHasContractor(props.work.id, props.job.id, i.id);
        props.refreshContractors();

        reload();
    });

    const doDeleteContractor = loading.wrap(async (i: IJobHasContractor) => {
        await props.deleteJobHasContractor(props.work.id, props.job.id, i.id);
        props.refreshContractors();

        reload();
    });

    const doDeleteInvitation = loading.wrap(async (i: RowType) => {
        if(i.type == ContractorType.INVITATION){
            await props.removeJobHasContractorInvitation(i.workId, i.jobId, i.contractorName, i.startDate)
        }else if(i.type == ContractorType.CONTRACTOR_REQUEST){
            await props.removeJobHasContractorRequest(i.workId, i.jobId, i.parentContractorId, i.contractorId)
        }

        props.refreshContractors();

        reload();
    });

    const reactivateContractor = loading.wrap(async (i: IJobHasContractor) => {
        await props.activateJobHasContractor(props.work.id, props.job.id, i.contractorId, i.id);
        props.refreshContractors();

        reload();
    });

    const confirmRemove = useConfirm({
        message: t('Are you sure to finalize contractor from this job?'),
        accept: doRemoveContractor
    });

    const confirmDelete = useConfirm({
        message: t('Are you sure to remove contractor from this job?'),
        accept: doDeleteContractor
    });

    const confirmDeleteInvitation = useConfirm({
        message: t('Are you sure to remove invitation from this job?'),
        accept: doDeleteInvitation
    });

    const [canAddSubContractorState, setCanAddSubContractorState] = React.useState<boolean>(true);

    const mainDepartment = props.job.departments.find(r => r.isMain == true);

    const jobContractorProps = {
        security: props.security,
        moduleManager: props.moduleManager,
        work: props.work,
    };

    const canSave = props.security.hasPermission('jobs.edit')
        || props.security.hasWorker(mainDepartment?.responsibleId);

    const perms = usePermissions(jobContractorProps, { ctx: { job: props.job } }, {
        EditContractorValues: { name: 'jobHasContractor.edit', default: canSave },
        canRemove: 'job.contractors.remove',
        authorize: {
            name: 'canForceJobHasContractorStatus',
            default: props.security.hasPermission('jobs.resources.validate') &&
                props.security.isGestorOrWorker()
        },
    });

    const canEdit = perms.get('EditContractorValues');

    const canRemove = props.security.hasPermission('jobHasContractor.remove')
        || props.security.hasWorker(mainDepartment?.responsibleId)
        || props.security.hasPolicy('consejero');

    const calculateCanRemove = async () => {
        const res = await props.moduleManager.requirePermission('jobHasContractor.remove', {
            security: props.security,
            job: props.job,
            work: props.work,
        }, canRemove);

        setCanRemoveState(res ?? false);
    };

    React.useEffect(() => {
        calculateCanRemove();
    }, []);

    const canForceJobHasContractorStatusContext = {
        props: props,
    };

    const workSettings: IWorkSettings | undefined = props.work.settingsObj;
    const HideByDefaultEndedContractors = workSettings?.hideByDefaultEndedContractors ?? false;

    React.useEffect(() => {
        if (props.selectedJobHasContractor) {
            const ctx = {
                job: props.job,
                security: props.security,
                workId: props.work.id,
                jobHasContractor: props.selectedJobHasContractor,
                contractor: props.selectedContractor,
                selfContractorId: props.selectedJobHasContractor?.id,
                selfJobHasContractorId: props.selectedJobHasContractor?.id,
            };
            props.moduleManager
                .requirePermission('job.subcontractors.add', ctx, true)
                .then((canAddSubContractor: boolean | undefined) => {
                    setCanAddSubContractorState(canAddSubContractor ?? false);
                });
        }
    }, [props.selectJobHasContractor]);

    const canAddContractor = (contractorId: number) => {
        //TODO: Remove keys
        if (props.work.id !== 23) {
            if(!props.contractorIds.includes(contractorId) && props.work.id === 22){
                return false;
            }

            return props.security.hasPermission('job.addSubc') || canSave || selfContractorId.current === contractorId ;
        } else {
            return canAddSubContractorState && props.security.hasPermission('job.addSubc');
        }
    }

    const cannotRemoveItself = (contractorId: number) => selfJobHasContractorsIds.current.includes(contractorId);

    const renderStatus = (r: RowType) => {
        return <ResourceStatus
            currentStatusType={r.contractorStatusType === 6 ? r.contractorStatusType : r.currentStatusType}
            resourceId={r.id}
            resourceType={ResourceType.JobHasContractor}
            workId={props.work.id} />;
    };

    const selectJobHasContractor = (rel: IJobHasContractor | undefined) => {
        if (!props.security.isContractor() && rel) {
            props.selectJobHasContractor(rel);
        }
    }

    const loadRequirements = (jobHasContractor: RowType) => {
        selectJobHasContractor(props.job.contractors.find(c => c.id == jobHasContractor.id));
        redirectTo(`/work/${props.work.id}/jobs/${props.job.id}/jobContractor/${jobHasContractor.id}/requirements`);
    };

    const applyFilters = (r: any) => {
        const fltrs = filters.filters;
        const fs = [
            [fltrs.resourceStatusTypes != undefined, r.currentStatusType == filters.filters.resourceStatusTypes],
            [fltrs.name?.length > 2, r.name?.toLowerCase().includes(filters.filters.name?.toLowerCase())],
        ];

        return fs.every(f => f[0] ? f[1] : true);
    }

    const settings = useWorkFormSettings({ work: props.work, scope: 'contractor' });

    const calculateRemoveIcon = (r: RowType) : string => {
        if(isJobContractor(r) && r.currentStatusType === ResourceStatusType.FINALIZED){
            return 'fas fa-redo';
        }else{
            return 'pi pi-minus-circle';
        }
    };

    const calculateRemoveOnClick = (r: RowType, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if(isJobContractor(r) && r.currentStatusType === ResourceStatusType.FINALIZED){
            reactivateContractor(r);
        }else if(isContractorRequest(r)){
            confirmDeleteInvitation(r, event)
        }else if(isInvitation(r)){
            confirmDeleteInvitation(r, event);
        }
        else{
            confirmRemove(r, event);
        }
    }

    const calculateRemoveTooltip = (r: RowType) : string => {
        if(isJobContractor(r) && r.currentStatusType === ResourceStatusType.FINALIZED){
            return t('Reactivate');
        }else if(isContractorRequest(r) || isInvitation(r)){
            return t('Remove job contractor invitation');
        }else{
            return t('Finalize contractor from job');
        }
    };

    const calculateRemoveDisabled = (r: RowType) : boolean => {
        if(!applyFilters(r) || cannotRemoveItself(r.id) || props.contractorIds.includes(r.contractorId)){
            return true;
        }else{
            return false;
        }
    }

    const [ contractors, setContractors ] = React.useState<RowType[]>([]);

    React.useEffect(() => {
        if (HideByDefaultEndedContractors && filters.filters.resourceStatusTypes != 3) {
            const filteredContractors = query.value.filter((c: RowType) => c.currentStatusType !== 3);
            setContractors(filteredContractors);
        } else {
            setContractors(query.value ?? []);
        }
    }, [query.value]);

    React.useEffect(() => {
        reload();
    }, [filters.filters]);

    const dataTable = useTreeTable<RowType>({
        expand: true,
        columns: [
            {
                title: 'Contractor', render: (c: any) =>
                    isJobContractor(c)
                        ? <span className={!applyFilters(c) ? 'disabled-text' : ''}
                            title={c.name}>
                            {c.name}
                        </span>
                        : (c.targetName != undefined)
                            ? <span title={t('Subcontractor request')} className={!applyFilters(c) ? 'disabled-text' : !c.parentId ? 'e' : 'e pad'}>
                                <i className='fas fa-id-card-alt' />
                                <span className='e'>{' ' + c.targetName}</span>
                            </span>
                            : isContractorRequest(c)
                                ? <span className={!applyFilters(c) ? 'disabled-text' : 'e'}>
                                    <span className='e'>{'  ' + c.contractorName}</span>
                                </span>
                                : isInvitation(c)
                                    ? <span className={!applyFilters(c) ? 'disabled-text' : ''}
                                        title={c.name}>{c.name}</span>
                                    : '',
                className: 'jhc',
                //TODO: remove Key
                sortKey: props.work.id != 21 ? 'contractor.name' : undefined
            },
            {
                title: 'job.relation.start-date',
                render: (d: any) =>
                    isJobContractor(d)
                        ? <span className={!applyFilters(d) ? 'disabled-tag' : 'r'}>
                            {canEdit && !props.security.isContractor() && <i
                                onClick={e => editOverlay.show(e, { type: 'date', data: d })}
                                className='pointer fas fa-edit' />}
                            <span className='e'>{DateUtils.format(d.startDate)}</span>
                        </span>
                        : isContractorRequest(d)
                            ? <span className={!applyFilters(d) ? 'disabled-tag' : ''}>{DateUtils.format(d.contractorStartDate)}</span>
                            : undefined,
                className: 'td-md job-start-date',
                sortKey: props.work.id != 21 ? 'startDate' : undefined
            },
            settings.show('shipmentNumber')
                ? {
                    title: 'job.contractor.shipment-number',
                    render: (d: any) =>
                        isJobContractor(d) && d.parentId == null
                            || d.shipmentNumber ? <span className={!applyFilters(d) ? 'disabled-tag' : 'r'}>
                            {(canEdit && !props.security.isContractor() || props.security.hasPolicy('compras')) &&
                                <i
                                    onClick={e => applyFilters(d) && editOverlay.show(e, { type: 'shipment-number', data: d })}
                                    className={!applyFilters(d) ? 'disabled-tag' : 'pointer fas fa-edit'} />}
                            <span className='e'>{d.shipmentNumber}</span>
                        </span> : null,
                    className: 'td-md job-shipment-number'
                }
                : undefined,
            {
                title: 'Jobs description',
                render: (d: any) =>
                    <span className={!applyFilters(d) ? 'disabled-tag' : 'r'}>
                        {isJobContractor(d) && canEdit && !props.security.isContractor() && <i
                            onClick={e => editOverlay.show(e, { type: 'description', data: d })}
                            className={!applyFilters(d) ? 'disabled-tag' : 'pointer fas fa-edit'} />}
                        {isJobContractor(d)  && !canEdit &&
                            <span className='e text-ellision' title={d.jobsDescription}>{d.jobsDescription}</span>}
                        {isJobContractor(d) && canEdit && props.security.isContractor() && !props.contractorIds.includes(d.contractorId) &&
                            <><i
                                onClick={e => editOverlay.show(e, { type: 'description', data: d })}
                                className={!applyFilters(d) ? 'disabled-tag' : 'pointer fas fa-edit'} />
                                <span className='e text-ellision' title={d.jobsDescription}>{d.jobsDescription}</span>
                            </>
                        }
                        {isJobContractor(d) && canEdit && props.security.isContractor() && props.contractorIds.includes(d.contractorId) &&
                                <span className='e text-ellision' title={d.jobsDescription}>{d.jobsDescription}</span>
                        }
                        {isContractorRequest(d) &&
                            <span className='e text-ellision' title={d.jobsDescription}>{d.jobsDescription}</span>}
                    </span>,
                className: 'td-md job-description'
            },
            {
                title: 'currentStatusType',
                render: (d: any) =>
                    (isJobContractor(d) && applyFilters(d))
                        ? renderStatus(d)
                        : <div className='r we'>
                            <Tag
                                className={!applyFilters(d) ? 'disabled-tag we' : 'we'}
                                value={t('contractor.request.pending')} />
                        </div>,
                className: 'td-md current_status_type',
                sortKey: props.work.id != 21 ? 'currentStatusType' : undefined
            },
            {
                title: 'Documentation', className: 'td-indicator center',
                render: (d: any) => {
                    if (isJobContractor(d)) {
                        return <div
                            onClick={
                                _ => {
                                    if (applyFilters(d)) { loadRequirements(d) }
                                }} className={applyFilters(d) ? 'pointer' : ''}>
                            <Tag value={`${d.validatedCount ?? 0}/${d.totalCount ?? 0}`} className={!applyFilters(d) ? 'disabled-tag' : ''} />
                        </div>
                    }
                    else {
                        return null;
                    }
                }
            },
            {
                title: 'Workers',
                className: 'td-indicator center',
                render: (d: any) => {
                    const authorized = d.countWorkersAuthorized ?? 0;
                    const total = d.countContractorWorkers ?? 0;
                    if (isJobContractor(d)) {
                        return <span className={applyFilters(d) ? 'pointer' : ''} onClick={() => {
                            if (applyFilters(d)) {
                                selectJobHasContractor(d);
                                goToJobWorkers(props.work.id, d.jobId, { relationId: d.id });
                            }
                        }}>
                            <Tag
                                value={`${authorized}/${total}`}
                                className={!applyFilters(d) ? 'disabled-tag' : ''} />
                        </span>
                    }
                    else {
                        return null;
                    }
                }
            },
            {
                title: 'Machineries',
                className: 'td-indicator center',
                render: (d: any) => {
                    const authorized = d.countMachineriesAuthorized ?? 0;
                    const total = d.countContractorMachineries ?? 0;

                    if (isJobContractor(d)) {
                        const tag = <Tag
                            value={`${authorized}/${total}`}
                            className={!applyFilters(d) ? 'disabled-tag' : ''} />;
                        return <span className={applyFilters(d) ? 'pointer' : ''} onClick={() => {
                            if (applyFilters(d)) {
                                selectJobHasContractor(d);
                                goToJobMachineries(props.work.id, d.jobId, { relationId: d.id });
                            }
                        }}>
                            {tag}
                        </span>
                    }
                    else {
                        return null;
                    }
                }
            },
            {
                title: 'Subcontractors',
                className: 'td-indicator center',
                render: (d: any) => {
                    const authorized = d.countSubContractorsAuthorized ?? 0;
                    const total = d.countContractorSubContractors ?? 0;
                    if (isJobContractor(d)) {
                        const tag = <Tag
                            value={`${authorized}/${total}`}
                            className={!applyFilters(d) ? 'disabled-tag' : ''} />;
                        return <span className={applyFilters(d) ? 'pointer' : ''} onClick={() => {
                            if (applyFilters(d)) {
                                selectJobHasContractor(d);
                                goToJobSubContractors(props.work.id, d.jobId, d.id)
                            };
                        }}>
                            {tag}
                        </span>
                    }
                    else {
                        return null;
                    }
                    // if (isJobContractor(d)) {
                    //     const data = contractorsViewModel.value?.find((m: any) => m.id == d.id);
                    //     return <span className={applyFilters(d) ? 'pointer' : ''} onClick={() => {
                    //         if (applyFilters(d)) {
                    //             selectJobHasContractor(d);
                    //             goToJobSubContractors(props.work.id, d.jobId, d.id)
                    //         };
                    //     }}>
                    //         <Tag value={`${data?.countSubContractorsAuthorized ?? 0}/${data?.countContractorSubContractors ?? 0}`} className={!applyFilters(d) ? 'disabled-tag' : ''} />
                    //     </span>
                    // }
                    // else {
                    //     return null;
                    // }
                }
            }
        ],
        actions: [
            canAddContractor
                ? {
                    icon: 'plus',
                    onClick: d => props.onRequestAddContractor(d),
                    tooltip: t('Add sub contractor'),
                    //TODO: remove Key
                    disabled: r => r.hierarchyLevel >= 4 || (!applyFilters(r) || !isJobContractor(r) || !canAddContractor(r.contractorId) || props.work.id == 23 && r.contractorId != selfContractorId.current) && !props.security.isGestor()
                } : undefined,
            (canRemoveState)
                ? {
                    icon: (r) => calculateRemoveIcon(r),
                    // r => isJobContractor(r) && r.currentStatusType === ResourceStatusType.FINALIZED
                    //     ? 'fas fa-redo'
                    //     : 'pi pi-minus-circle',
                    onClick: (r, event) => calculateRemoveOnClick(r, event),
                        // isJobContractor(r) && r.currentStatusType === ResourceStatusType.FINALIZED
                        // ? reactivateContractor(r)
                        // : confirmRemove(r, event),
                    tooltip: r => calculateRemoveTooltip(r),
                        // isJobContractor(r) && r.currentStatusType === ResourceStatusType.FINALIZED
                        // ? t('Reactivate')
                        // : t('Finalize job contractor'),
                    disabled: r => calculateRemoveDisabled(r),
                        // !applyFilters(r) || !isJobContractor(r) || cannotRemoveItself(r.id),
                }
                : undefined,
            (perms.get('canRemove'))
                ? {
                    icon: 'trash',
                    onClick: confirmDelete,
                    tooltip: t('Remove job contractor'),
                    disabled: r => !applyFilters(r) || !isJobContractor(r) || cannotRemoveItself(r.id),
                }
                : undefined,
            perms.get('authorize')
                ? {
                    icon: `pi pi-check${props.work.id == 23 ? '-square' : ''}`,
                    tooltip: t('Force resource validation'),
                    onClick: r => dialogs.showFromEvent('force-validation', r)(),
                    disabled: r => !applyFilters(r)
                }
                : undefined

        ], //pi-check-square
        data: contractors,
        toggleApplications: true,
    });

    return <div className='he JobHasContractors'>
        <Portal container='#breadcrumb-right'>
            <div>
                {filters.renderAsButton({ className: 'fas fa-filter pointer' })}
            </div>
        </Portal>
        <BreadcrumbItem
            text={t('Contractors')}
            onClick={() => setSelectedContractor(undefined)} />

        {dialogs.render('force-validation', { title: t('Force resource validation') }, (d: IJobHasContractor) =>
            <ForceValidationResourceContainer
                currentStatusType={d.currentStatusType}
                workId={props.work.id}
                requestClose={() => {
                    dialogs.clear();
                    reload();
                }}
                resourceType={ResourceType.JobHasContractor}
                resourceId={d.id} />
        )}

        {editOverlay.render()}

        {selectedContractor &&
            <EditJobHasContractorContainer
                contractor={selectedContractor}
                job={props.job}
                onCancel={() => setSelectedContractor(undefined)}
                work={props.work} />}
        {!selectedContractor && <>
            {dataTable()}
        </>}
    </div>
}

export const JobContractors = React.memo(JobContractorsImpl, (prev, next) => {
    return prev.work.id === next.work.id;
});
