import * as React from 'react';

import { BreadcrumbItem, RequireRole, G, useForm, useToast, useTranslation, ValidationBuilder } from '@components';
import { IContractorInvitation, IJob, ISecurity, IWork, NotificationType, ModuleManager } from '@models';
import { useLoading } from '@utils/hooks';
import './CreateContractorInvitation.scss';
import dateUtils from '@utils/date-utils';
import { redirectTo } from '@utils';

export interface IProps {
    createContractorInvitation: Function;
    job?: IJob;
    onCancel?: Function;
    work: IWork;
    security: ISecurity;
    success: Function;
    onCreate?: Function;
    moduleManager: ModuleManager;
}

export const validateInvitation = (workId: number, jobId?: number, isGestorOrWorker?: boolean) => {
    if (workId == 19 && jobId != null) {
        return ValidationBuilder.new<IContractorInvitation>()
            .notEmpty('email')
            .email('email')
            .notEmpty('name')
            .notEmpty('startDate')
            .notEmpty('jobsDescription')
            .notEmptyIf(isGestorOrWorker, 'shipmentNumber')
            .lift()
    }
    else if(workId != 19 && jobId != null){
        return ValidationBuilder.new<IContractorInvitation>()
            .notEmpty('email')
            .email('email')
            .notEmpty('name')
            .notEmpty('startDate')
            .notEmpty('jobsDescription')
            .lift()
    }else{
        return ValidationBuilder.new<IContractorInvitation>()
            .notEmpty('email')
            .email('email')
            .notEmpty('name')
            .lift()
    }
}


 function CreateContractorInvitationImpl(props: IProps) {
    const { t } = useTranslation();
    const loading = useLoading();
    const toast = useToast();
    const workId = props.work.id;
    const jobId = props.job?.id;

    const form = useForm<IContractorInvitation>({
        initialValues: {
            workId: workId,
            jobId: props.job?.id,
            email: '',
            name: '',
            subject: t('ContractorInvitation.default.subject'),
            message: props.work.id != 23 ? t('ContractorInvitation.default.message') : t('ContractorInvitation.Azsa.message'),
        },
        validate: validateInvitation(workId, jobId, props.security.isGestorOrWorker()),
        validateOnMount: true,
    });

    const today = new Date();

    const doSendInvitation = loading.wrap(async (..._: any) => {
        const resp = await props.createContractorInvitation(props.work.id, form.values);
        if (resp?.value && jobId != null) {
            props.success({
                message: t('Contractor invitation send successfully'),
                ctx: 'global',
                action: 'create',
            });
            props.onCreate?.();
            redirectTo(`/work/${workId}/jobs/${jobId}/contractors`);
        }
        else if (resp?.value && jobId == null) {
            props.success({
                message: t('Contractor invitation send successfully'),
                ctx: 'global',
                action: 'create',
            });
            props.onCreate?.();
        }
        else {
            toast.error(t('Can not send contractor invitation'));
        }
    });

    const formIsValid = () => {
        if (props.job != null) {
            if ((dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job!.startDate)) >= 0) &&
                (dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job!.endDate!)) < 0) &&
                (dateUtils.dateISTodayOrFuture(form.values.startDate))) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    React.useEffect(() => {
        formIsValid();
    }, [form.values.startDate]);

    return <div className='he'>
        <BreadcrumbItem
            text={t('Contractor invitation')} />

        {toast.render()}

        <div style={{}} className={(workId == 19 && jobId != null) ? 'constractorInvitation lg pd form-1 l200' : 'c lg pd form-1 l200'}>
            <G label={t('Contractor.Name')}>
                {form.input('name')}
            </G>
            <G label={t('Email')}>
                {form.input('email', { type: 'email' })}
            </G>
            { (workId != 23 || jobId != null) &&
            <G label={t('Message')}>
                {form.textarea('message', {readOnly: workId != 21? true : false })}
            </G>}
            {(jobId != null) && <>
                <G label={t('StartDate')} className={'dateTime'}>
                    <div className='c'>
                        {form.input('startDate', { type: 'date' })}
                        {form.values.startDate && !(dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job!.startDate)) >= 0) &&
                            <small className='p-error date'>{t('job.error.jobStartDate') + `: ${dateUtils.formatFromUtc(dateUtils.toUtc(props.job!.startDate))}`}</small> ||
                        (form.values.startDate && !(dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job!.endDate!)) < 0)) &&
                            <small className='p-error date'>{t('job.error.endDate') + `: ${dateUtils.formatFromUtc(dateUtils.toUtc(props.job!.endDate!))}`}</small> ||
                        form.values.startDate && !(dateUtils.dateISTodayOrFuture(dateUtils.parseDate(form.values.startDate))) &&
                            <small className='p-error date'>{t('job.error.startDate') + `: ${dateUtils.formatFromUtc(today)}`}</small>}
                    </div>
                </G>
                <G label={t('JobsDescription')}>
                    {form.textarea('jobsDescription')}
                </G>
                {props.work.id != 21 &&
                    <RequireRole oneOf={['gestor', 'worker']}>
                        <G label={t('Shipment number')} className={'ShipmentNumber'}>
                            {form.textarea('shipmentNumber')}
                        </G>
                    </RequireRole>
                }
            </>}
            <span className='e' />
            {form.errorBox()}
            <span className='e' />
            <div className='r'>
                <div className='e' />
                {loading.render()}
                <button
                    className='primary'
                    disabled={!form.values.email || form.isInvalid() || loading.isLoading() || !formIsValid()}
                    onClick={doSendInvitation}>
                    {t('Send invitation')}
                </button>
            </div>
        </div>
    </div>
}

export default function CreateContractorInvitation(props: IProps) {

    return props
        .moduleManager
        .renderComponent<IProps>(
            'CreateContractorInvitation',
            { ...props },
            CreateContractorInvitationImpl,
            true);
}