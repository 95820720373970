import {
    IDocumentType, IPropertyGroupType, IPropertyType,
    IWork, IWorkPermission, IWorkShift, IWorkspace, IDepartment,
    INotificationTemplate,
    IWorkerSettings,
    IMachinerySettings,
    IContractorSettings,
    IJobSettings,
    IEventTrigger,
} from '@models';
import { IUserData } from '@models/identity';
import {
    IRequirementGroup, IRequirementType, IRequirementTypeDependency,
    IRequirementTypeDocumentRestriction, IRequirementTypeSuggestion, IRequirementTypeValidationHolder
} from '@models/requirements';
import { IFieldValidation, INotificationMessage, IWorkHasModule } from '@models/works';
import { getClient, gql, noCache, m, q } from './graphql';
import { ensureType, formatProperty } from './utils';

export async function getEventTriggers(workId: number) {
    return q(`query GetEventTriggers($workId: Int!) {
        eventTriggers(workId: $workId) {
            id, name, code, workId, eventTriggerTypeId, resourceTypeId,
        }
    }`, { workId }, { returnPath: 'eventTriggers' });
}

export async function saveEventTrigger(workId: number, data: IEventTrigger) {
    return q(`mutation SaveEventTrigger($workId: Int!, $data: EventTriggerGraphInputType!) {
        saveEventTrigger(workId: $workId, data: $data) {
            isError, error, value
        }
    }`, { workId, data }, { returnPath: 'saveEventTrigger' });
}

export async function getLogs(filter: string | null = null) {
    const client = getClient('getLogs');
    const query = gql`
query GetLogs($filter: String) {
    logs(filter: $filter) {
        id, userId, dateTime, action, actionData, ctx, data
    }
}`;

    const resp = await client.query({
        query,
        fetchPolicy: noCache,
        variables: {
            filter,
        }
    });

    return resp.data.logs;
}

export async function initialize() {
    const client = getClient('initialize');
    const query = gql`
query Initialize {
    availableModules {
        id, name, description, version, resources, hasFrontend, frontendResource
    },
    users {
        id, userName, email, roles, policies, departments
    },
    roles {
        id, name
    },
    works {
        id, name, description, countryId, settings, defaultEmail, helpDeskEmail, footerResource, contextData, users {
            id, userName, email, roles, policies
        },
        workHasModules {
            id, workId, moduleName, isActive,
        },
        settings,
        contractorInvitationTemplateId,
        resetPasswordTemplateId,
        workspaces {
            id, name, description, isActive, countryId,
            regionId, countyId, contact, address,
            location, postalCode, phone, fax, email,
            properties {
                id, propertyGroupTypeId, workspaceId, value
            }
        },
        legalForms {
            id, name, hideContractorProperties, workerTypes{
                id, workerType{
                    id, name
                }
            }
        },
        workShifts {
            id, name, startMinutes, endMinutes
        },
        propertyGroupTypes {
            id, name, title, styleClassName, description, containerType, style, objectType, isInternal, mandatory, hidable,
            properties {
                id, name, title, typeId, placeholder, help, parentId, propertyOrder
            }
        },
        requirementValidationTypes {
            id, name
        },
        contractorSettings {
            id, workId, showGeneralIndicators, legalFormMandatory, jhcStartDateMandatory, jhcDescriptionMandatory, cifCantBeRepeated, canAddSubWorkers, canAddSubMachineries,
        },
        machinerySettings {
            id, workId, showGeneralIndicators, subTypeMandatory, onlyLicenseNumber,
        },
        workerSettings {
            id, workId, showGeneralIndicators, showWorkerImage, workerTypeIsMandatory
        },
        jobSettings {
            id, workId, skipInformationLoadedState,
            colorizeContractorIndicators,
            freeRequirementsAreMandatory,
            omitJobHasContractorDefaultNotification,
            showResourceIncidenceIndicator,
            jobHasContractorNotificationTargetPolicy,
            informationLoadedNotificationPolicy,
            noInformationLoadedState,
        },
        departments {
            id, name, isOblique
        },
    }
}`;

    const resp = await client.query({ query, fetchPolicy: noCache });

    return resp;
}

export async function getUserById(id: number) {
    return q(`
query GetUserById($id: Int!) {
    userById(id: $id) {
        id, userName, email, roles, policies
    }
}`, { id }, { returnPath: 'userById' });
}

export async function getUsers() {
    const client = getClient('getUsers');
    const query = gql`
query Initialize {
    users {
        id, userName, email, roles, policies
    },
    roles {
        id, name
    }
}`;

    const resp = await client.query({ query, fetchPolicy: noCache });

    return resp;
}

export async function saveUser(user: IUserData) {
    const client = getClient('saveUser');
    const mutation = gql`
mutation SaveUser($user: UserInputType!) {
    saveUser(user: $user) {
        isError, error, value
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: { user }
    });

    return resp;
}

export async function removeUser(id: number) {
    const client = getClient('removeUser');
    const mutation = gql`
        mutation RemoveUser($id: Int!) {
            removeUser(id: $id) {
                isError, error, value
            }
        }`;

    const resp = await client.mutate({
        mutation,
        variables: { id }
    });

    return resp;
}

export async function getWorks() {
    const client = getClient('getWorks');
    const query = gql`
query GetWorks {
    works {
        id, name, description, countryId, settings, defaultEmail, helpDeskEmail, footerResource, contextData, users {
            id, userName, email, roles, policies
        },
        workHasModules {
            id, workId, moduleName, isActive,
        },
        contractorSettings {
            id, workId, showGeneralIndicators, legalFormMandatory, jhcStartDateMandatory, jhcDescriptionMandatory, cifCantBeRepeated, canAddSubWorkers, canAddSubMachineries,
        },
        workerSettings {
            id, workId, showGeneralIndicators, showWorkerImage, workerTypeIsMandatory
        },
        machinerySettings {
            id, workId, showGeneralIndicators, subTypeMandatory, onlyLicenseNumber,
        },
        jobSettings {
            id, workId, skipInformationLoadedState, colorizeContractorIndicators, freeRequirementsAreMandatory,
            omitJobHasContractorDefaultNotification,
            showResourceIncidenceIndicator,
            jobHasContractorNotificationTargetPolicy,
            informationLoadedNotificationPolicy,
            noInformationLoadedState,
        },
        settings,
        contractorInvitationTemplateId,
        resetPasswordTemplateId,
        workspaces {
            id, name, description, isActive, countryId,
            regionId, countyId, contact, address,
            location, postalCode, phone, fax, email,
            properties {
                id, propertyGroupTypeId, workspaceId, value
            }
        },
        legalForms{
            id, name, workerTypes{
                id, workerType{
                    id, name
                }
            }
        },
        workShifts {
            id, name, startMinutes, endMinutes
        },
        propertyGroupTypes {
            id, name, title, styleClassName, description, containerType, style, objectType, isInternal, mandatory, hidable,
            properties {
                id, name, title, typeId, placeholder, help, parentId, propertyOrder
            }
        },
        requirementValidationTypes {
            id, name
        },
        departments {
            id, name, isOblique
        },
    }
}`;

    const resp = await client.query({ query, fetchPolicy: noCache });

    return resp;
}

export async function getWorkResources(id: number) {
    return q(`query GetWorkResources($id: Int!) {
        work(id: $id) {
            users {
                id, userName, email,
            },
            departments {
                id, name, code, description, isOblique, notifyJobCreation
            },
            workFunctions {
                id, name
            }
        }
    }`, { id }, { returnPath: 'work' });
}

export async function getWorkDepartments(id: number) {
    return q(`query GetWorkDepartments($id: Int!) {
        work(id: $id) {
            departments {
                id, name, code, description, isOblique, notifyJobCreation, policies
            }
        }
    }`, { id }, { returnPath: 'work.departments' });
}

export async function saveDepartment(inputData: IDepartment) {
    const department = ensureType<IDepartment>(inputData, {
        exclude: ['workers']
    });

    return m(`mutation SaveDepartment($department: DepartmentInputType!) {
        saveDepartment(department: $department) {
            hasValue, value, error
        }
    }`, { department }, { returnPath: 'saveDepartment' });
}

export async function getWork(id: number) {
    const client = getClient('getWork');
    const query = gql`
query GetWorks($id: Int!) {
    work(id: $id) {
        id, name, description, countryId, settings, helpDeskEmail, footerResource, contextData, users {
            id, userName, email, roles, policies
        },
        workHasModules {
            id, workId, moduleName, isActive,
        },
        contractorSettings {
            id, workId, showGeneralIndicators, legalFormMandatory, jhcStartDateMandatory, jhcDescriptionMandatory, cifCantBeRepeated, canAddSubWorkers, canAddSubMachineries
        },
        workerSettings {
            id, workId, showGeneralIndicators, showWorkerImage, workerTypeIsMandatory
        },
        machinerySettings {
            id, workId, showGeneralIndicators, subTypeMandatory, onlyLicenseNumber,
        },
        jobSettings {
            id, workId, skipInformationLoadedState, colorizeContractorIndicators, freeRequirementsAreMandatory,
            omitJobHasContractorDefaultNotification,
            showResourceIncidenceIndicator,
            jobHasContractorNotificationTargetPolicy,
            informationLoadedNotificationPolicy,
            noInformationLoadedState,
        },
        settings,
        contractorInvitationTemplateId,
        resetPasswordTemplateId,
        workspaces {
            id, name, description, isActive, countryId,
            regionId, countyId, contact, address,
            location, postalCode, phone, fax, email,
            properties {
                id, propertyGroupTypeId, workspaceId, value
            }
        },
        legalForms {
            id, name, hideContractorProperties, workerTypes {
                id, workerType {
                    id, name
                }
            }
        },
        workShifts {
            id, name, startMinutes, endMinutes
        },
        propertyGroupTypes {
            id, name, title, styleClassName, description, containerType, style, objectType, isInternal, mandatory, hidable,
            properties {
                id, name, title, typeId, placeholder, help, parentId, propertyOrder
            }
        },
        requirementGroups {
            id, name, title, description, orderValue, legend
        },
        requirementValidationTypes {
            id, name
        }
    }
}`;

    const resp = await client.query({
        query, fetchPolicy: noCache,
        variables: {
            id
        }
    });

    return resp;
}

export async function saveWork(work: Partial<IWork>) {
    const client = getClient('saveWork');
    const mutation = gql`
mutation SaveWork($work: WorkInputType!) {
    saveWork(work: $work) {
        isError, error, value
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: { work }
    });

    return resp;
}

// workspaces
export async function saveWorkspace(inputData: IWorkspace) {
    const workspace = {
        ...inputData,
        properties: inputData.properties?.map(formatProperty(inputData.workId))
    }
    return m(`
mutation SaveWorkspace($workspace: WorkspaceInputType!) {
    saveWorkspace(workspace: $workspace) {
        isError, error, value, hasValue
    }
}
    `, { workspace }, { returnPath: 'saveWorkspace' });
}

export async function removeWorkspace(id: number) {
    const client = getClient('removeWorkspace');
    const mutation = gql`
mutation RemoveWorkspace($id: Int!) {
    removeWorkspace(id: $id) {
        isError, error, value
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id
        }
    });

    return resp;
}

// work shifts
export async function saveWorkShift(workShift: IWorkShift) {
    const client = getClient('saveWorkShift');
    const mutation = gql`
mutation SaveWorkShift($workShift: WorkShiftInputType!) {
    saveWorkShift(workShift: $workShift) {
        isError, error, value
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            workShift
        }
    });

    return resp;
}

export async function removeWorkShift(id: number) {
    const client = getClient('removeWorkShift');
    const mutation = gql`
mutation RemoveWorkShift($id: Int!) {
    removeWorkShift(id: $id) {
        isError, error, value
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id
        }
    });

    return resp;
}

// document types
export async function getDocumentTypes() {
    const client = getClient('getDocumentTypes');
    const query = gql`
query GetDocumentTypes {
    documentTypes {
        id, name, description, isActive
    },
}`;

    const resp = await client.query({ query, fetchPolicy: noCache });

    return resp;
}

export async function saveDocumentType(dt: IDocumentType) {
    const client = getClient('saveDocumentType');
    const mutation = gql`
mutation SaveDocumentType($doc: DocumentTypeInputType!) {
    saveDocumentType(documentType: $doc) {
        isError, error, value
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: { doc: dt }
    });

    return resp;
}

export async function removeDocumentType(id: number) {
    const client = getClient('removeDocumentType');
    const mutation = gql`
mutation RemoveDocumentType($id: Int!) {
    removeDocumentType(id: $id) {
        isError, error, value
    }
}
    `;

    const resp = await client.mutate({
        mutation,
        variables: {
            id
        }
    });

    return resp;
}

export async function associateAppUserToWorker(
    workId: number,
    workerId: number,
    user: any,
    forceNew: boolean | undefined = false
) {
    const client = getClient('associateAppUserToWorker');
    const mutation = gql`
mutation AssociateAppUserToWorker(
    $workId: Int!,
    $workerId: Int!,
    $user: UserInputType!,
    $forceNew: Boolean) {
    associateAppUserToWorker(workId: $workId, workerId: $workerId, user: $user, forceNewUser: $forceNew) {
        isError, error, value, hasValue
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: { workId, workerId, user, forceNew }
    });

    return resp.data.associateAppUserToWorker;
}

export async function loadWorkPermissions(workId: number) {
    return q(`query GetWorkPermissions($id: Int!) {
        work(id: $id) {
            permissions {
                id, action, name, roles, policies, expression
            }
        }
    }`, { id: workId }, { returnPath: 'work.permissions' });
}

export async function saveWorkPermission(permission: IWorkPermission) {
    return m(`mutation SaveWorkPermission($permission: PermissionInputType!) {
        saveWorkPermission(permission: $permission) {
            isError, error, value
        }
    }`, { permission });
}

export async function removeWorkPermission(workId: number, id: number) {
    return m(`mutation RemoveWorkPermission($workId: Int!, $id: Int!) {
        removeWorkPermission(workId: $workId, id: $id) {
            isError, error, value
        }
    }`, { workId, id });
}

export async function getJobPropertyGroups(workId: number) {
    return q(`query GetJobPropertyGroups($workId: Int!) {
        work(id: $workId) {
            jobPropertyGroups {
                id, name, title, description, styleClassName,
                properties {
                    id, name, title, propertyType, parentId
                }
            }
        }
    }`, { workId }, {
        returnPath: 'work.jobPropertyGroups'
    });
}

export async function getPropertyGroupTypes(workId: number) {
    return q(`query GetPropertyGroupTypes($workId: Int!) {
        work(id: $workId) {
            propertyGroupTypes {
                id, name, title, description, styleClassName, mandatory, hidable,
                objectType, style, isInternal
                properties {
                    id, name, title, help, placeholder, typeId, parentId, propertyOrder
                }
            }
        }
    }`, { workId }, {
        returnPath: 'work.propertyGroupTypes'
    });
}

export async function savePropertyGroupType(workId: number, data: IPropertyGroupType) {
    const input = {
        workId,
        id: data.id,
        name: data.name,
        title: data.title,
        description: data.description,
        containerType: data.containerType,
        styleClassName: data.styleClassName,
        objectType: data.objectType,
        isActive: true,
        isInternal: data.isInternal,
        style: data.style,
        hidable: data.hidable ?? false,
        mandatory: data.mandatory ?? false,
    };

    return m(`mutation SavePropertyGroupType($workId: Int!, $input: PropertyGroupTypeGraphInputType!) {
        savePropertyGroupType(workId: $workId, data: $input) {
            isError, error, value
        }
    }`, { input, workId }, { returnPath: 'savePropertyGroupType' });
}

export async function removePropertyGroupType(workId: number, id: number) {
    return m(`mutation RemovePropertyGroupType($workId: Int!, $id: Int!) {
        removePropertyGroupType(workId: $workId, id: $id) {
            isError, error, value
        }
    }`, { id, workId }, { returnPath: 'removePropertyGroupType' });
}

export async function savePropertyType(workId: number, data: IPropertyType) {
    const input = {
        id: data.id,
        name: data.name,
        title: data.title,
        typeId: data.typeId,
        parentId: data.parentId,
        placeholder: data.placeholder,
        isActive: true,
        help: data.help,
        propertyOrder: data.propertyOrder,
        selectOptions: data.selectOptions,
    };

    return m(`mutation SavePropertyType($workId: Int!, $input: PropertyTypeGraphInputType!) {
        savePropertyType(workId: $workId, data: $input) {
            isError, error, value
        }
    }`, { input, workId }, { returnPath: 'savePropertyType' });
}

export async function removePropertyType(workId: number, id: number) {
    return m(`mutation RemovePropertyType($workId: Int!, $id: Int!) {
        removePropertyType(workId: $workId, id: $id) {
            isError, error, value
        }
    }`, { workId, id }, { returnPath: 'removePropertyType' });
}

export async function getWorkRequirementGroups(workId: number, requirementTargetType: number | undefined = undefined) {
    const data = await q(`query WorkRequirementGroups($workId: Int!) {
        work(id: $workId) {
            requirementGroups {
                id, name, title, description, color, style, icon, workId, orderValue, legend,
                requirementTypes {
                    id, workId, name, title, description, targetType, requirementGroupId, kindId, isActive,
                    expirationTypeId, expirationValue, expirationThreshold, policy, validationTypeId,
                    isShared, expression, isPublic, isResourcePicture,
                    criteria, template,
                    orderValue, color, notifyContractors, notifyTitle, notifyPolicy, notifyResponsible,
                    expirationRequired,
                    requireJobInformationAccess,
                    messageTemplateId,
                    billingTypeId,
                    usersExcludedChargedNotifications,
                    dependencies {
                        id, requirementTypeId, dependsOnRequirementId, dependencyType, propagate,
                    },
                    documentRestrictions {
                        id, requirementTypeId, name, title, excludeMimeTypes, mimeTypes, regexp, limitFileSize, isActive
                    },
                    suggestions {
                        id, name, requirementTypeId,
                    },
                    validationHolders {
                        id, requirementTypeId, policy, isBlocking, validationOrder, appUserId, departmentId,
                        workFunctionId, onlyNotifications,
                        jobResponsible, jobRso, jobImpliedRso,
                        optional
                    }
                }
            }
        }
    }`, { workId }, { returnPath: 'work.requirementGroups' });

    const filterRequirementType = (r: IRequirementType) =>
        r.targetType == requirementTargetType;

    if (requirementTargetType) {
        return data.map((g: IRequirementGroup) => {
            return { ...g, requirementTypes: g.requirementTypes?.filter(filterRequirementType) };
        });
    }
    else {
        return data;
    }
}

export function getRequirementType(workId: number, id: number) {
    return q(`query GetRequirementType($workId: Int!, $id: Int!) {
        work(id: $workId) {
            requirementType(id: $id) {
                id, workId, name, title, description, targetType, requirementGroupId, kindId, isActive,
                expirationTypeId, expirationValue, policy,
                isShared, expression, validationTypeId, isPublic, isResourcePicture,
                criteria, template,
                color, orderValue, notifyContractors, notifyTitle, , notifyPolicy, notifyResponsible,
                expirationRequired,
                requireJobInformationAccess,
                dependencies {
                    id, requirementTypeId, dependsOnRequirementId, dependencyType, propagate
                },
                documentRestrictions {
                    id, requirementTypeId, name, title, excludeMimeTypes, mimeTypes, regexp, limitFileSize, isActive
                },
                suggestions {
                    id, name, requirementTypeId,
                },
                validationHolders {
                    id, requirementTypeId, policy, isBlocking, validationOrder, appUserId, departmentId, workFunctionId,
                    onlyNotifications,
                    jobResponsible, jobRso, jobImpliedRso, optional,
                }
            }
        }
    }`, { workId, id }, { returnPath: 'work.requirementType' });
}

export function saveWorkRequirementGroup(workId: number, inputData: IRequirementGroup) {
    const data = ensureType<IRequirementGroup>(inputData, {
        exclude: 'requirementTypes',
    });

    return m(`mutation SaveWorkRequirementGroup($workId: Int!, $data: RequirementGroupGraphInputType!) {
        saveRequirementGroup(workId: $workId, data: $data) {
            hasValue, value, error
        }
    }`, { workId, data }, { returnPath: 'saveRequirementGroup' });
}

export function saveWorkRequirementType(workId: number, inputData: IRequirementType) {
    const data = ensureType<IRequirementType>(inputData, {
        exclude: ['dependencies', 'documentRestrictions', 'suggestions', 'validationHolders'],
    });

    return m(`mutation SaveWorkRequirementType($workId: Int!, $data: RequirementTypeGraphInputType!) {
        saveRequirementType(workId: $workId, data: $data) {
            hasValue, value, error
        }
    }`, { workId, data }, { returnPath: 'saveRequirementType' });
}

export function removeWorkRequirementTypeDependency(workId: number, requirementTypeDependencyId: number) {
    return m(`mutation RemoveWorkRequirementTypeDependency($workId: Int!, $requirementTypeDependencyId: Int!) {
        removeRequirementTypeDependency(workId: $workId, requirementTypeDependencyId: $requirementTypeDependencyId) {
            hasValue, value, error
        }
    }`, { workId, requirementTypeDependencyId }, { returnPath: 'removeRequirementTypeDependency' });
}

export function saveRequirementTypeSuggestion(workId: number, data: IRequirementTypeSuggestion) {
    return m(`mutation SaveRequirementTypeSuggestion($workId: Int!, $data: RequirementTypeSuggestionGraphInputType!) {
        saveRequirementTypeSuggestion(workId: $workId, data: $data) {
            hasValue, value, error
        }
    }`, { workId, data }, { returnPath: 'saveRequirementTypeSuggestion' });
}

export function removeRequirementTypeSuggestion(workId: number, id: number) {
    return m(`mutation RemoveRequirementTypeSuggestion($workId: Int!, $id: Int!) {
        removeRequirementTypeSuggestion(workId: $workId, id: $id) {
            hasValue, value, error
        }
    }`, { workId, id }, { returnPath: 'removeRequirementTypeSuggestion' });
}

export function saveRequirementTypeValidationHolder(workId: number, inputData: IRequirementTypeValidationHolder) {
    const data = ensureType<IRequirementTypeValidationHolder>(inputData);
    return m(`mutation SaveRequirementTypeValidationHolder($workId: Int!, $data: RequirementTypeValidationHolderGraphInputType!) {
        saveRequirementTypeValidationHolder(workId: $workId, data: $data) {
            hasValue, value, error
        }
    }`, { workId, data }, { returnPath: 'saveRequirementTypeValidationHolder' });
}

export function removeRequirementTypeValidationHolder(workId: number, id: number) {
    return m(`mutation RemoveRequirementTypeValidationHolder($workId: Int!, $id: Int!) {
        removeRequirementTypeValidationHolder(workId: $workId, id: $id) {
            hasValue, value, error
        }
    }`, { workId, id }, { returnPath: 'removeRequirementTypeValidationHolder' });
}

export function saveWorkRequirementTypeDependency(workId: number, inputData: IRequirementTypeDependency) {
    const data = ensureType<IRequirementTypeDependency>(inputData);
    return m(`mutation SaveWorkRequirementTypeDependency($workId: Int!, $data: RequirementTypeDependencyGraphInputType!) {
        saveRequirementTypeDependency(workId: $workId, data: $data) {
            hasValue, value, error
        }
    }`, { workId, data }, { returnPath: 'saveRequirementTypeDependency' });
}

export function removeRequirementTypeDocumentRestriction(workId: number, requirementTypeId: number, restrictionId: number) {
    return m(`mutation RemoveRequirementTypeDocumentRestriction($workId: Int!, $requirementTypeId: Int!, $restrictionId: Int!) {
        removeRequirementTypeDocumentRestriction(workId: $workId, requirementTypeId: $requirementTypeId, id: $restrictionId) {
            hasValue, value, error
        }
    }`,
        { workId, requirementTypeId, restrictionId },
        { returnPath: 'removeRequirementTypeDocumentRestriction' });
}

export function saveRequirementTypeDocumentRestriction(workId: number, requirementTypeId: number, rawData: IRequirementTypeDocumentRestriction) {
    const data = ensureType<IRequirementTypeDocumentRestriction>(rawData);

    return m(`mutation SaveRequirementTypeDocumentRestriction($workId: Int!, $requirementTypeId: Int!, $data: RequirementTypeDocumentRestrictionGraphInputType!) {
        saveRequirementTypeDocumentRestriction(workId: $workId, requirementTypeId: $requirementTypeId, data: $data) {
            hasValue, value, error
        }
    }`,
        { workId, requirementTypeId, data },
        { returnPath: 'saveRequirementTypeDocumentRestriction' });
}

export async function getNotificationTemplates(workId: number) {
    return q(`query GetNotificationTemplates($id: Int!) {
        work(id: $id) {
            notificationTemplates {
                id, name, title, message, workId
            }
        }
    }`, { id: workId }, { returnPath: 'work.notificationTemplates' });
}

export async function saveNotificationTemplate(workId: number, data: INotificationTemplate) {
    return m(`mutation SaveNotificationTemplate($workId: Int!, $data: NotificationTemplateGraphInputType!) {
        saveNotificationTemplate(workId: $workId, data: $data) {
            isError, error, value
        }
    }`, { workId, data });
}

export async function removeNotificationTemplate(workId: number, id: number) {
    return m(`mutation RemoveNotificationTemplate($workId: Int!, $id: Int!) {
        removeNotificationTemplate(workId: $workId, id: $id) {
            isError, error, value
        }
    }`, { workId, id });
}

export async function getNotificationMessages(workId: number) {
    return q(`query GetNotificationMessages($id: Int!) {
        work(id: $id) {
            notificationMessages {
                id, action, workId, resourceType, byMail, notificationTemplateId, restrictToResourceType, actionKey,
                restrictToResourceId,
                notificationTemplate {
                    id, message, title, name
                }
            }
        }
    }`, { id: workId }, { returnPath: 'work.notificationMessages' });
}

export async function saveNotificationMessage(workId: number, inputData: INotificationMessage) {
    const data = ensureType<INotificationMessage>(
        inputData,
        { exclude: ['notificationTemplate'] });

    return m(`mutation SaveNotificationMessage($workId: Int!, $data: NotificationMessageGraphInputType!) {
        saveNotificationMessage(workId: $workId, data: $data) {
            isError, error, value
        }
    }`, { workId, data });
}

export async function removeNotificationMessage(workId: number, id: number) {
    return m(`mutation RemoveNotificationMessage($workId: Int!, $id: Int!) {
        removeNotificationMessage(workId: $workId, id: $id) {
            isError, error, value
        }
    }`, { workId, id });
}

export function getFieldValidations(workId: number) {
    return q(`query GetWorkValidations($workId: Int!) {
        work(id: $workId) {
            validations {
                id, name, field, value, resourceType, errorMessage, workId,
            }
        }
    }`, { workId }, { returnPath: 'work.validations' });
}

export function removeFieldValidation(workId: number, id: number) {
    return m(`mutation RemoveFieldValidation($workId: Int!, $id: Int!) {
        removeFieldValidation(workId: $workId, id: $id) {
            isError, error, value
        }
    }`, { workId, id }, { returnPath: 'removeFieldValidation' });
}

export function saveFieldValidation(workId: number, rawData: IFieldValidation) {
    const data = ensureType<IFieldValidation>(rawData);

    return m(`mutation SaveWorkValidation($workId: Int!, $data: FieldValidationGraphInputType!) {
        saveFieldValidation(workId: $workId, data: $data) {
            isError, error, value
        }
    }`, { workId, data }, { returnPath: 'saveFieldValidation' });
}

export async function saveWorkerSettings(workId: number, rawData: IWorkerSettings) {
    const client = getClient('saveWorkerSettings');
    const data = ensureType<IWorkerSettings>(rawData);
    const mutation = gql`
mutation SaveWorkerSettings($workId: Int!, $data: WorkerSettingsGraphInputType!) {
    saveWorkerSettings(workId: $workId, data: $data) {
        isError, error, value
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: { workId, data }
    });

    return resp;
}

export async function saveMachinerySettings(workId: number, rawData: IMachinerySettings) {
    const client = getClient('saveMachinerySettings');
    const data = ensureType<IMachinerySettings>(rawData);
    const mutation = gql`
mutation SaveMachinerySettings($workId: Int!, $data: MachinerySettingsGraphInputType!) {
    saveMachinerySettings(workId: $workId, data: $data) {
        isError, error, value
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: { workId, data }
    });

    return resp;
}

export async function saveContractorSettings(workId: number, rawData: IContractorSettings) {
    const client = getClient('saveContractorSettings');
    const data = ensureType<IContractorSettings>(rawData);
    const mutation = gql`
mutation SaveContractorSettings($workId: Int!, $data: ContractorSettingsGraphInputType!) {
    saveContractorSettings(workId: $workId, data: $data) {
        isError, error, value
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: { workId, data }
    });

    return resp;
}

export async function saveJobSettings(workId: number, rawData: IJobSettings) {
    const client = getClient('saveJobSettings');
    const data = ensureType<IJobSettings>(rawData);
    const mutation = gql`
mutation SaveJobSettings($workId: Int!, $data: JobSettingsGraphInputType!) {
    saveJobSettings(workId: $workId, data: $data) {
        isError, error, value
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: { workId, data }
    });

    return resp;
}

export async function saveWorkHasModule(workId: number, rawData: IWorkHasModule) {
    const client = getClient('saveWorkHasModule');
    const data = ensureType<IWorkHasModule>(rawData);
    const mutation = gql`
mutation SaveWorkHasModule($workId: Int!, $data: WorkHasModuleGraphInputType!) {
    saveWorkHasModule(workId: $workId, data: $data) {
        isError, error, value
    }
}`;

    const resp = await client.mutate({
        mutation,
        variables: { workId, data }
    });

    return resp;
}
